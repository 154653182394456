import {createSlice} from "@reduxjs/toolkit";
import {type PayloadAction} from "@reduxjs/toolkit";

import {type SignalRMessage} from "../../services/signalR/models/Messages";
import {type HubConnectionState} from "@microsoft/signalr";

export interface MessagesState {
  connectionStatus: HubConnectionState | undefined;
  messages: SignalRMessage[] | [];
}

const initialState: MessagesState = {
  connectionStatus: undefined,
  messages: [],
};

export const messagesSlice = createSlice({
  name: "signalRMessages",
  initialState,
  reducers: {
    setConnection: (
      state,
      {payload}: PayloadAction<HubConnectionState | undefined>,
    ) => {
      state.connectionStatus = payload;
    },
    add: (state, {payload}: PayloadAction<SignalRMessage[]>) => {
      for (const line of payload) {
        const message: SignalRMessage = {...line, isRead: false};
        state.messages = [...state.messages, message];
      }
    },
    markAsRead: (state, {payload}: PayloadAction<string>) => {
      const index = state.messages.findIndex(m => m.traceId === payload);
      if (index !== -1) {
        state.messages[index].isRead = true;
      }
    },
  },
});

export const {add, setConnection, markAsRead} = messagesSlice.actions;

export default messagesSlice.reducer;
