import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, Icon, SpinnerSize, TooltipHost} from "@fluentui/react";

import styles from "./Editable.module.scss";
import Select from "./Select";
import Editable from "./Editable";
import IsaacLoading from "../../isaacLoading/IsaacLoading";
import {removeMirrorFromValue} from "./EditableConstructionService";
import {updateEditableProps} from "../../../pages/construction/constructionSlice";
import useEditableHook from "../../../hooks/useEditableHook";
import type {RootState} from "../../../store";
import type {IEditableItemProps} from "./IEditableItemProps";
import {EditableType} from "../../../types/EditableItem";

const EditableItem: React.FunctionComponent<IEditableItemProps> = ({
  value,
  parent,
  entity: entityName,
  type,
  entityId,
  property: propertyName,
  extraInfo,
  isDisabled,
}) => {
  const [newValue, setNewValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string | undefined>(
    undefined,
  );
  const [isEditing, setEditing] = useState(false);

  const {validateConstructionPropertyChanged} = useEditableHook();
  const {defaultCalculationParams} = useSelector(
    (state: RootState) => state.construction,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setNewValue(value);
  }, []);

  useEffect(() => {
    if (isDisabled) {
      setNewValue(value);
      setValidationError(undefined);
    }
  }, [isDisabled]);

  const validateCalculationDeformationParameters = (): string | undefined => {
    const defaultDeformation =
      defaultCalculationParams?.deformationParameters.find(
        df => df.id.toLocaleLowerCase() === propertyName.toLocaleLowerCase(),
      );

    if (defaultDeformation !== undefined) {
      const isAboveMin =
        defaultDeformation.min === null ||
        Number(newValue) >= defaultDeformation.min;

      const isBelowMax =
        defaultDeformation.max === null ||
        Number(newValue) <= defaultDeformation.max;
      return isAboveMin && isBelowMax
        ? undefined
        : `Value is outside the following range ${defaultDeformation.min} <= ${newValue} <= ${defaultDeformation.max} `;
    }
    return "Calculation deformation parameters";
  };
  const validateProperty = async (): Promise<void> => {
    setLoading(prevState => !prevState);
    let validationResponse: string | undefined;
    // Note: validate calculation parameters first on the FE. BE will also have validation in place.
    if (
      propertyName.toLocaleLowerCase().includes("deformation") ||
      propertyName.toLocaleLowerCase().includes("overdimensioning")
    ) {
      validationResponse = validateCalculationDeformationParameters();
    } else {
      validationResponse = await validateConstructionPropertyChanged(
        entityName,
        propertyName,
        entityId,
        removeMirrorFromValue(newValue),
      );
    }
    setLoading(prevState => !prevState);

    if (validationResponse !== undefined) {
      setValidationError(validationResponse);
    } else {
      setValidationError(undefined);
      dispatch(
        updateEditableProps({
          parent,
          entityName,
          entityId: entityId ?? "",
          propertyName,
          propertyValue: newValue,
        }),
      );
    }
  };

  const handleValueChanged = (newValue: any): void => {
    setNewValue(newValue as string);
  };

  const handleClick = (): void => {
    if (isDisabled) return;

    setEditing(true);
  };

  const handleBlur = (): void => {
    setEditing(false);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    validateProperty();
  };

  const selectComponent = (type: EditableType, isDisabled: boolean): any => {
    switch (type) {
      case EditableType.Select:
        return (
          <Select
            isDisabled={isDisabled}
            extraInfo={extraInfo}
            value={newValue}
            module={entityName}
            property={propertyName}
            onValueChanged={handleValueChanged}
            onDropdownClosed={handleBlur}
          />
        );
      case EditableType.Number:
        return (
          <input
            value={newValue}
            type="number"
            disabled={isDisabled}
            onChange={e => {
              handleValueChanged(e.target.value);
            }}
          />
        );
      case EditableType.Text:
        return (
          <input
            value={newValue}
            disabled={isDisabled}
            type="text"
            onChange={e => {
              handleValueChanged(e.target.value);
            }}
          />
        );
      // @typescript-eslint\no-unused-vars
      case EditableType.CheckBox:
        return (
          <Checkbox
            disabled={isDisabled}
            checked={Boolean(newValue)}
            onChange={(ev, data) => {
              handleValueChanged(data);
            }}
          />
        );
      default:
        return newValue;
    }
  };

  return (
    <div className={styles.container}>
      <Editable
        isDisabled={isDisabled}
        value={newValue}
        handleClick={handleClick}
        handleBlur={handleBlur}
        isEditing={isEditing}
        type={type}
        isValid={validationError === undefined}>
        {selectComponent(type, isDisabled)}
      </Editable>
      <IsaacLoading loading={loading} size={SpinnerSize.small} message="" />
      {validationError && !loading && (
        <TooltipHost content={validationError}>
          <Icon iconName="Error" className={styles.errorIcon} />
        </TooltipHost>
      )}
    </div>
  );
};

export default EditableItem;
