import * as signalR from "@microsoft/signalr";

import type {SignalRNegotiationResponse} from "./models/SignalRNegotiationObjects";
import type {SignalRMessage} from "./models/Messages";

class SignalRService {
  private readonly connection: signalR.HubConnection;

  private constructor(negotiationObject: SignalRNegotiationResponse) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(negotiationObject.url, {
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => negotiationObject.accessToken,
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Error)
      .build();
    this.connection.start().catch(() => {
      console.error("Failed to start connection!!!");
    });

    this.events = onMessageReceived => {
      this.connection.on("SendMessageAsync", (message: string) => {
        onMessageReceived(JSON.parse(message) as unknown as SignalRMessage[]);
      });
    };
  }

  public events: (
    onMessageReceived: (message: SignalRMessage[]) => void,
  ) => void;

  public static async create(): Promise<SignalRService | undefined> {
    const negotiateObject = await this.initializeAsync();
    if (negotiateObject !== undefined) {
      return new SignalRService(negotiateObject);
    }
    return undefined;
  }

  private static async initializeAsync(): Promise<
    SignalRNegotiationResponse | undefined
  > {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ISAAC_API_URL}/messageHub/negotiate`,
        {method: "POST"},
      );

      if (!response.ok) {
        throw new Error("Failed to negotiate connection!!!");
      }

      const negotiateObject: SignalRNegotiationResponse = await response.json();

      return negotiateObject;
    } catch (error) {
      console.error("Server side error!!!");
      return undefined;
    }
  }
}

export default SignalRService;
