// Note: This need to be removed after refactoring editable items

const checkIfBoolean = (value: string | boolean): string => {
  if (typeof value === "boolean") {
    return String(value);
  }
  return value;
};

/** @deprecated Tagged for removal. Editable props are now part of redux state. */
const isValueMirrored = (value: string | undefined): boolean => {
  if (value && isNaN(Number(value))) {
    return value.includes("-");
  }
  return false;
};

/** @deprecated Tagged for removal. Editable props are now part of redux state. */
const removeMirrorFromValue = (value: string | undefined): string => {
  if (value) {
    return isValueMirrored(value)
      ? value?.substring(value.indexOf("-") + 1, value.length)
      : checkIfBoolean(value);
  }
  return "";
};

export {isValueMirrored, removeMirrorFromValue};
